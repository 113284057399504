import * as React from "react";
import { useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SlaComponent from "../../../../components/Sla";
import api from "../../../../api";
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  createMuiTheme,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  createTheme,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ExpandMore as ExpandMoreIcon,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import { differenceInDays, format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { common } from "@mui/material/colors";
import { ThemeProvider } from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";

import InfoIcon from "@material-ui/icons/Info";
import CreateIcon from "@material-ui/icons/Create";
import { useStore } from "../../../../stores/rootStore";

const useStyles = makeStyles(() => ({
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#000000",
      color: "#ffffff",
      "& > .MuiTableCell-root": {
        color: "#ffffff",
      },
    },
  },
  tableCell: {
    fontSize: "0.75rem",
  },
  textField: {
    fontSize: "0.75rem",
  },
  checkbox: {
    fontSize: "0.75rem",
  },
  customTextField: {
    fontSize: "0.75rem",
  },
}));

const _fetchData = (
  setAtividade,
  setCurrentStage,
  setPageStates,
  pageStates,
  id
) => {
  (async () => {
    setPageStates({ ...pageStates, loading: true });
    const { data, ok } = await api.get<any>(`/atividades/${id}`);

    if (!ok) {
      setPageStates({ ...pageStates, loading: false, error: true });
      return;
    }

    const {
      data: { Etapas },
    } = await api.get<any>(`/todas-etapas`);

    if (!Etapas) {
      setPageStates({ ...pageStates, loading: false, error: true });
      return;
    }

    // const { data: checklistData } = await api.get<any>("/checklists", {
    //   tipo: data.regime_contratacao,
    // });

    // setChecklist(checklistData);
    let sortedData = {
      ...data,
      etapas: [
        ...Etapas.map((etp) => {
          if (etp.nome === "Início do colaborador na obra") {
            etp.responsavel = data.email_obra;
          }
          return etp;
        }).filter(
          (x) =>
            ![...data.etapas, data.etapa_atual]
              .map((item) => ({
                prefixo: item.prefixo,
                nome: item.nome,
                fase: item.fase,
                sla: item.sla,
                responsavel: item.responsavel,
              }))
              .some((item) => item.nome === x.nome)
        ),
        ...data.etapas.filter((i) => i.prefixo !== data.etapa_atual.prefixo),
        data.etapa_atual,
      ],
    };

    sortedData.etapas = sortedData.etapas.sort((a: any, b: any) =>
      parseInt(a.prefixo?.split("/")[0]) < parseInt(b.prefixo?.split("/")[0])
        ? -1
        : parseInt(b.prefixo?.split("/")[0]) >
          parseInt(a.prefixo?.split("/")[0])
        ? 1
        : 0
    );
    setPageStates({ ...pageStates, loading: false, error: false });
    setAtividade({
      ...sortedData,
      // checklist: checklistData.map((item: any) => ({
      //   nome: item.nome,
      //   ...(sortedData.checklist.find((i: any) => i.nome === item.nome)
      //     ? {
      //         resposta: sortedData.checklist.find((i: any) => i.nome === item.nome)
      //           .resposta,
      //       }
      //     : { resposta: false }),
      // })),
      // treinamentos: treinamentos.map((item: any) => ({
      //   nome: item,
      //   ...(sortedData.treinamentos.find((i: any) => i.nome === item)
      //     ? {
      //         resposta: sortedData.treinamentos.find((i: any) => i.nome === item)
      //           .resposta,
      //       }
      //     : { resposta: false }),
      // })),
    });
    setCurrentStage(sortedData.etapa_atual);
    // setObs(sortedData.etapa_atual.obs);
  })();
};

const getBody = (files, atividade, finalizar, colab, obs, email) => {
  // const obsInput = (
  //   document.getElementById("obs-text-field") as HTMLInputElement
  // ).value;
  // const colabDefinidoInput = (
  //   document.getElementById(
  //     "nome-colab-definido-text-field"
  //   ) as HTMLInputElement
  // )?.value;
  // const filesInput: any = (
  //   document.getElementById("icon-button-file") as HTMLInputElement
  // ).files;
  const filesInput = files;
  const colabDefinidoInput = colab;
  const obsInput = obs;
  let data;
  // if (finalizar) {
  if (colabDefinidoInput && colabDefinidoInput !== "") {
    data = {
      atividade: {
        ...atividade,
        candidato_escolhido: {
          nome: colabDefinidoInput.split("(")[0],
          ...(colabDefinidoInput.split("(")[1] && {
            telefone: "(" + colabDefinidoInput.split("(")[1],
          }),
        },
        email,
      },
      etapa: { obs: obsInput, email },
    };
  } else {
    data = { atividade: {...atividade, email}, etapa: { obs: obsInput, email } };
  }
  // } else {
  // data = {
  // ...atividade.etapa_atual,
  // obs: obsInput,
  // };
  // }

  if (filesInput && filesInput.length > 0) {
    const formData: any = new FormData();
    for (let i = 0; i < filesInput.length; ++i) {
      const f = filesInput[i];
      formData.append("files.anexo", f, f.name);
    }
    formData.append("data", JSON.stringify(data));
    return formData;
  }
  return data;
};

const handleApproval = async (
  files,
  atividade,
  setAtividade,
  setCurrentStage,
  setPageStates,
  pageStates,
  id,
  colab,
  obs,
  email,
  approveNextEtapa?
) => {
  console.log(atividade.responsavel_contratacao);
  if (
    atividade.etapa_atual.nome === "Recrutamento e Seleção" &&
    (!atividade.responsavel_contratacao ||
      atividade.responsavel_contratacao === "")
  ) {
    alert('Favor preencher o campo "Responsável Contratação"');
    return;
  }

  const finalizar = (
    document.getElementById("checkbox-finalizar") as HTMLInputElement
  ).checked;
  const cancelar = (
    document.getElementById("checkbox-cancelar") as HTMLInputElement
  ).checked;

  const body = getBody(files, atividade, finalizar, colab, obs, email);
  if (finalizar || cancelar) {
    const { data, ok } = await api.post<any>(
      cancelar
        ? "/atividades/cancelar"
        : approveNextEtapa
        ? "/atividades/etapa/aprovar-proxima"
        : "/atividades/etapa/aprovar",
      body
    );
    if (!ok) {
      alert("Erro");
      console.log(data);
    } else {
      alert("Sucesso");
    }
  } else {
    const { data, ok } = await api.put<any>(
      `/etapas/${atividade.etapa_atual.id}`,
      body
    );
    if (!ok) {
      alert("Erro");
      console.log(data);
    } else {
      alert("Sucesso");
    }
  }
  _fetchData(setAtividade, setCurrentStage, setPageStates, pageStates, id);
  // setShowModal(false);
};

const RightPanel = ({
  currentStage,
  atividade,
  setAtividade,
  setCurrentStage,
  setPageStates,
  pageStates,
  id,
  email,
}) => {
  const [obs, setObs] = React.useState<string>("");
  const [colab, setColab] = React.useState<string>("");
  const [dataAdmissao, setDataAdmissao] = React.useState<Date | null>();
  const [files, setFiles] = React.useState<FileList>();
  const classes = useStyles();
  const { userStore } = useStore();

  const handleSaveButton = () => {
    let editedAtividade = { ...atividade };
    if (atividade.etapa_atual.nome === "Recrutamento e Seleção") {
      if (!dataAdmissao) {
        alert("Favor preencher a data de admissão do colaborador");
        return;
      }

      if (!colab || colab === "") {
        alert("Favor preencher o nome do colaborador");
        return;
      }

      editedAtividade = { ...atividade, data_admissao: dataAdmissao };
    }

    handleApproval(
      files,
      editedAtividade,
      setAtividade,
      setCurrentStage,
      setPageStates,
      pageStates,
      id,
      colab,
      obs,
      email
    );
  };

  const handleApproveNextEtapa = () => {
    handleApproval(
      files,
      atividade,
      setAtividade,
      setCurrentStage,
      setPageStates,
      pageStates,
      id,
      colab,
      obs,
      email,
      true
    );
  };

  const canEdit = () => {
    if (
      currentStage.nome === atividade.etapa_atual.nome &&
      currentStage.data_fim == null
    ) {
      if (!currentStage.responsavel_grupo) {
        alert(
          "Ocorreu um erro.\nFavor entrar em contato com o time de TI para regularizar o cadastro da Etapa."
        );
      }

      if (
        currentStage.responsavel_grupo &&
        currentStage.responsavel_grupo === userStore.role.name
      ) {
        return true;
      }
    }

    return false;
  };

  if (canEdit()) {
    return (
      <Box sx={{ marginLeft: "8px" }}>
        <Card>
          <CardContent>
            <Box>
              <Typography component="div">
                <Box
                  sx={{
                    padding: "0.75rem",
                    textAlign: "left",
                    fontSize: "0.75rem",
                    fontWeight: "bold",
                  }}
                >
                  {currentStage.nome}
                </Box>
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography component="div">
                <Box>
                  <Box sx={{ m: 1 }}>
                    <Box
                      sx={{
                        padding: "0.75rem",
                        fontSize: "0.75rem",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Observação
                    </Box>
                    <hr style={{ width: "100%" }} />
                    <Box
                      sx={{
                        padding: "0.75rem",
                        fontSize: "0.75rem",
                        textAlign: "left",
                      }}
                    >
                      <TextField
                        InputProps={{
                          classes: {
                            input: classes.textField,
                          },
                        }}
                        label={
                          <Typography style={{ fontSize: "0.75rem" }}>
                            Insira aqui a observação
                          </Typography>
                        }
                        id="obs-text-field"
                        // key="rejection-modal-text-field"
                        name="obs"
                        placeholder="Insira aqui observação"
                        variant="standard"
                        value={obs}
                        onChange={(e) => setObs(e.target.value)}
                        multiline
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box sx={{ m: 1 }}>
                    <Box
                      sx={{
                        padding: "0.75rem",
                        fontSize: "0.75rem",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Anexos
                    </Box>
                    <hr style={{ width: "100%" }} />
                    <Box
                      sx={{
                        padding: "0.75rem",
                        fontSize: "0.75rem",
                        textAlign: "left",
                      }}
                    >
                      <input
                        key="rejection-modal-file-input"
                        accept="file/*"
                        id="icon-button-file"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => setFiles(e.target.files)}
                        multiple
                      />
                      <label htmlFor="icon-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<CloudUploadIcon />}
                          component="span"
                          style={{ fontSize: "0.60rem" }}
                        >
                          Escolher arquivo(s)
                        </Button>
                      </label>

                      {files &&
                        files.length > 0 &&
                        Array.from(files).map((file) => (
                          <Typography
                            component="span"
                            style={{ fontSize: "0.60rem" }}
                          >
                            {file.name}
                            <br />
                          </Typography>
                        ))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormControlLabel
                      classes={{
                        label: classes.checkbox,
                      }}
                      control={
                        <Checkbox
                          // checked={finalizar}
                          // onChange={() => setFinalizar(!finalizar)}
                          id="checkbox-finalizar"
                          name="checkbox-finalizar"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                        />
                      }
                      label="Finalizar Etapa"
                    />
                    <FormControlLabel
                      classes={{
                        label: classes.checkbox,
                      }}
                      control={
                        <Checkbox
                          // checked={finalizar}
                          // onChange={() => setFinalizar(!finalizar)}
                          id="checkbox-cancelar"
                          name="checkbox-cancelar"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                        />
                      }
                      label="Inativar Atividade"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSaveButton()}
                      style={{ fontSize: "0.70rem" }}
                      // disabled={ true }
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="outlined"
                      // onClick={() => handleReproval()}
                      style={{ marginTop: "8px", fontSize: "0.70rem" }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Box>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ marginLeft: "8px" }}>
      <Card>
        <CardContent>
          <Box>
            <Typography component="div">
              <Box
                sx={{
                  padding: "0.75rem",
                  textAlign: "left",
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                }}
              >
                {currentStage.nome}
              </Box>
            </Typography>
            <hr style={{ width: "100%" }} />
            <Typography component="div">
              <Box>
                <Box sx={{ m: 1 }}>
                  <Box
                    sx={{
                      padding: "0.75rem",
                      fontSize: "0.75rem",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Observação
                  </Box>
                  <hr style={{ width: "100%" }} />
                  <Box
                    sx={{
                      padding: "0.75rem",
                      fontSize: "0.75rem",
                      textAlign: "left",
                    }}
                  >
                    <Typography component="div">
                      <Box
                        sx={{
                          m: 1,
                          width: "100%",
                        }}
                      >
                        Colaboradores indicados
                      </Box>
                      <Box
                        sx={{
                          m: 1,
                          width: "100%",
                        }}
                      >
                        {atividade.colabs_indicados.map((colab: any) => (
                          <span>
                            {colab.nome} - {colab.telefone}
                            <br />
                          </span>
                        ))}
                      </Box>
                    </Typography>
                    <span>{currentStage.obs ? currentStage.obs : "-"}</span>
                  </Box>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Box
                    sx={{
                      padding: "0.75rem",
                      fontSize: "0.75rem",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Anexos
                  </Box>
                  <hr style={{ width: "100%" }} />
                  <Box
                    sx={{
                      padding: "0.75rem",
                      fontSize: "0.75rem",
                      textAlign: "left",
                    }}
                  >
                    {currentStage.anexo &&
                      currentStage.anexo.length > 0 &&
                      Array.from(currentStage.anexo).map((file: any) => (
                        <Box
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(
                              `https://app.opus.inc/apis/contratacao${file.url}`,
                              "_blank"
                            )
                          }
                        >
                          <Typography
                            component="span"
                            style={{
                              fontSize: "0.75rem",
                              textDecoration: "underline",
                              color: "blue",
                            }}
                          >
                            {file.name}
                            <br />
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                </Box>
                {/* <ThemeProvider theme={buttonTheme}> */}
                {currentStage.nome === atividade.etapa_atual.nome &&
                  currentStage.data_fim == null && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormControlLabel
                        classes={{
                          label: classes.checkbox,
                        }}
                        control={
                          <Checkbox
                            // checked={finalizar}
                            // onChange={() => setFinalizar(!finalizar)}
                            id="checkbox-finalizar"
                            name="checkbox-finalizar"
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                          />
                        }
                        label="Finalizar Etapa"
                      />
                      <FormControlLabel
                        classes={{
                          label: classes.checkbox,
                        }}
                        control={
                          <Checkbox
                            // checked={finalizar}
                            // onChange={() => setFinalizar(!finalizar)}
                            id="checkbox-cancelar"
                            name="checkbox-cancelar"
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                          />
                        }
                        label="Inativar Atividade"
                      />
                        <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSaveButton()}
                        style={{ fontSize: "0.70rem" }}
                        disabled={atividade.etapa_atual.prefixo !== "7/7"}
                        >
                        Salvar
                        </Button>
                      <Button
                        variant="outlined"
                        // onClick={() => handleReproval()}
                        style={{ marginTop: "8px", fontSize: "0.70rem" }}
                      >
                        Cancelar
                      </Button>
                    </Box>
                  )}
                {/* </ThemeProvider> */}
              </Box>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default function Exibicao() {
  const classes = useStyles();
  const [obs, setObs] = React.useState<string>("");
  const [obsModalOpen, setObsModalOpen] = React.useState<boolean>(false);
  const [savingObs, setSavingObs] = React.useState<boolean>(false);

  const [atividade, setAtividade] = React.useState<any>({
    id: 0,
    tipo: "",
    etapa_atual: {
      id: 0,
      nome: "",
      fase: "",
      sla: "",
      responsavel: "",
      atividade: 0,
      ordem: 0,
      data_inicio: format(new Date(), "yyyy-MM-dd"),
      data_fim: format(new Date(), "yyyy-MM-dd"),
      mao_de_obra: "",
      created_at: "",
      updated_at: "",
      atual: true,
      obs: "",
      anexo: [],
    },
    mao_de_obra: "",
    vaga: "",
    regime_contratacao: "",
    horario_trabalho: "",
    responsavel_entrevista: "",
    obs: "",
    atividade: {
      id: 0,
      empreendimento: "",
      atividade: "",
      funcao: "",
      quantidade: 0,
      produtividade: 0,
      data_inicio_lb: format(new Date(), "yyyy-MM-dd"),
      data_fim_lb: format(new Date(), "yyyy-MM-dd"),
      created_at: new Date(),
      updated_at: new Date(),
    },
    data_inicio: format(new Date(), "yyyy-MM-dd"),
    data_inicio_processo: format(new Date(), "yyyy-MM-dd"),
    previsao_desligamento: format(new Date(), "yyyy-MM-dd"),
    created_at: new Date(),
    updated_at: new Date(),
    colabs_indicados: [],
    candidato_escolhido: "",
    etapas: [
      {
        id: 0,
        nome: "",
        fase: "",
        sla: "",
        responsavel: "",
        atividade: 0,
        ordem: 0,
        data_inicio: format(new Date(), "yyyy-MM-dd"),
        data_fim: format(new Date(), "yyyy-MM-dd"),
        mao_de_obra: "",
        created_at: new Date(),
        updated_at: new Date(),
        atual: false,
        obs: "",
        anexo: [],
      },
    ],
    checklist: [],
    treinamentos: [],
  });
  const [currentStage, setCurrentStage] = React.useState<any>({
    id: 0,
    nome: "",
    fase: "",
    sla: "",
    responsavel: "",
    atividade: 0,
    ordem: 0,
    data_inicio: format(new Date(), "yyyy-MM-dd"),
    data_fim: format(new Date(), "yyyy-MM-dd"),
    mao_de_obra: "",
    created_at: "",
    updated_at: "",
    atual: true,
    obs: "",
    anexo: [],
  });
  // const [dropdown, setDropdown] = React.useState<boolean>(false);
  // const [finalizar, setFinalizar] = React.useState<boolean>(false);
  // const [obs, setObs] = React.useState<string>("");
  // const [files, setFiles] = React.useState<FileList>();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [pageStates, setPageStates] = React.useState({
    success: false,
    error: false,
    loading: true,
  });
  const params = useParams();
  const { userStore } = useStore();

  React.useEffect(() => {
    _fetchData();
  }, []);

  const _fetchData = () => {
    (async () => {
      setPageStates({ ...pageStates, loading: true });
      const { data, ok } = await api.get<any>(`/atividades/${params.id}`);
      const {
        data: { Etapas },
      } = await api.get<any>(`/todas-etapas`);

      if (!ok) {
        setPageStates({ ...pageStates, loading: false, error: true });
      }

      if (!Etapas) {
        setPageStates({ ...pageStates, loading: false, error: true });
      }
      // const { data: checklistData } = await api.get<any>("/checklists", {
      //   tipo: data.regime_contratacao,
      // });

      // setChecklist(checklistData);

      let sortedData = {
        ...data,
        etapas: [
          ...Etapas.filter(
            (x) =>
              ![...data.etapas, data.etapa_atual]
                .map((item) => ({
                  prefixo: item.prefixo,
                  nome: item.nome,
                  fase: item.fase,
                  sla: item.sla,
                  responsavel: item.responsavel,
                }))
                .some((item) => item.nome === x.nome)
          ),
          ...data.etapas.filter((i) => i.prefixo !== data.etapa_atual.prefixo),
          data.etapa_atual,
        ],
      };

      sortedData.etapas = sortedData.etapas.sort((a: any, b: any) =>
        parseInt(a.prefixo?.split("/")[0]) < parseInt(b.prefixo?.split("/")[0])
          ? -1
          : parseInt(b.prefixo?.split("/")[0]) >
            parseInt(a.prefixo?.split("/")[0])
          ? 1
          : 0
      );
      setPageStates({ ...pageStates, loading: false, error: false });
      setAtividade({
        ...sortedData,
      });
      setCurrentStage(sortedData.etapa_atual);
    })();
  };

  if (pageStates.loading) {
    return <CircularProgress size={60} />;
  }

  const buttonTheme = createMuiTheme({
    palette: {
      text: { primary: common.white, secondary: common.white },
      primary: { main: "rgba(255, 0, 0, 0.8)", contrastText: "#ffffff" },
      secondary: { main: "rgba(0, 232, 9, 0.79)", contrastText: "#ffffff" },
    },
  });

  const tableTheme = createTheme({
    palette: {
      secondary: {
        light: "#2c2c2c",
        main: "#3a3a3a",
        dark: "#000000",
      },
      primary: {
        light: "#ffffff",
        main: "#ffffff",
        dark: "#ffffff",
      },
    },
  });

  const handleChecklist = (checklist: any, index: any) => {
    const _atividade = { ...atividade };
    _atividade.checklist[index] = {
      ...checklist,
      resposta: !checklist.resposta,
    };
    setAtividade({ ..._atividade });
  };

  const handleTreinamentos = (treinamento: any, index: any) => {
    const _atividade = { ...atividade };
    _atividade.treinamento[index] = {
      ...treinamento,
      resposta: !treinamento.resposta,
    };
    setAtividade({ ..._atividade });
  };

  const handleSaveObs = async () => {
    setSavingObs(true);

    const { ok } = await api.put<any>(`/atividades/${atividade.id}`, {
      obs,
    });

    setSavingObs(false);

    if (!ok) {
      alert("Ocorreu um erro ao tentar salvar a observação.");
    } else {
      alert("Observação salva com sucesso.");
      setObsModalOpen(false);
      _fetchData();
    }
  };

  return (
    <Box
      // width="100%"
      // height="100%"
      sx={{
        m: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="editar-etapa-atual"
        aria-describedby="modal-para-editar-a-etapa-atual"
        key="rejection-modal-component"
        disableEnforceFocus
      >
        <Paper
          // width="100%"
          style={{
            width: "70%",
            position: "absolute",
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
        >
          <Typography component="div">
            <Box
              sx={{
                m: 1,
                width: "100%",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              Editar Etapa
            </Box>
          </Typography>
          <Box
            sx={{
              m: 1 /*, display: "grid", gridTemplateColumns: "2fr 1fr 1fr"*/,
            }}
          >
            <Box>
              {atividade.etapa_atual.nome === "Recrutamento e Seleção" &&
                atividade.colabs_indicados.length > 0 &&
                atividade.colabs_indicados.filter((i: any) => i.nome !== "")
                  .length > 0 && (
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        width: "100%",
                      }}
                    >
                      Colaboradores indicados
                    </Box>
                    <Box
                      sx={{
                        m: 1,
                        width: "100%",
                      }}
                    >
                      {atividade.colabs_indicados.map((colab: any) => (
                        <span>
                          {colab.nome} {colab.telefone && `-${colab.telefone}`}
                          <br />
                        </span>
                      ))}
                    </Box>
                  </Typography>
                )}
              <Box
                sx={{ m: 1, display: "grid", gridTemplateColumns: "1fr 1fr" }}
              >
                <Box sx={{ p: 1 }}>
                  <Typography component="div">
                    <Box sx={{ m: 1, textAlign: "left", fontWeight: "bold" }}>
                      Observação
                    </Box>
                  </Typography>
                  <hr style={{ width: "100%" }} />
                  <TextField
                    id="obs-text-field"
                    key="rejection-modal-text-field"
                    name="obs"
                    label="Insira aqui a observação"
                    placeholder="Insira aqui observação"
                    variant="standard"
                    multiline
                    fullWidth
                  />
                  {atividade.etapa_atual.nome === "Recrutamento e Seleção" && (
                    <TextField
                      id="nome-colab-definido-text-field"
                      key="rejection-modal-text-field"
                      name="nome-colab-definido"
                      label="Insira aqui o nome do colaborador definido"
                      placeholder="Insira aqui o nome do colaborador definido"
                      variant="standard"
                      multiline
                      fullWidth
                    />
                  )}
                </Box>
                <Box sx={{ p: 1 }}>
                  <Typography component="div">
                    <Box sx={{ m: 1, textAlign: "left", fontWeight: "bold" }}>
                      Anexo
                    </Box>
                  </Typography>
                  <hr style={{ width: "100%" }} />
                  <input
                    key="rejection-modal-file-input"
                    accept="file/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: "none" }}
                    multiple
                  />
                  <label htmlFor="icon-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      component="span"
                    >
                      Escolher arquivo(s)
                    </Button>
                  </label>
                  {(
                    document.getElementById(
                      "icon-button-file"
                    ) as HTMLInputElement
                  )?.files?.length > 0 &&
                    Array.from(
                      (
                        document.getElementById(
                          "icon-button-file"
                        ) as HTMLInputElement
                      ).files
                    ).forEach((file) => <span>{file.name}</span>)}
                </Box>
              </Box>
            </Box>
          </Box>
          <ThemeProvider theme={buttonTheme}>
            <Box
              sx={{
                m: 1,
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 0.5fr 1fr 1fr",
              }}
            >
              <div />
              <Button
                variant="contained"
                color="secondary"
                // onClick={() => handleApproval()}
              >
                Aprovar
              </Button>
              <div />
              <Button
                variant="contained"
                color="primary"
                // onClick={() => handleReproval()}
              >
                Reprovar
              </Button>
              <div />
            </Box>
          </ThemeProvider>
        </Paper>
      </Modal>
      <Modal
        open={obsModalOpen}
        onClose={() => setObsModalOpen(false)}
        aria-labelledby="editar-observacao"
        aria-describedby="modal-para-editar-a-observacao"
        key="rejection-modal-component"
        disableEnforceFocus
      >
        <Paper
          elevation={5}
          style={{
            width: "70%",
            position: "absolute",
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            padding: "2rem",
            display: "grid",
            placeItems: "center",
          }}
        >
          <TextField
            label="Insira aqui a observação"
            id="obs-text-field"
            // key="rejection-modal-text-field"
            name="obs"
            placeholder="Insira aqui observação"
            variant="standard"
            value={obs}
            onChange={(e) => setObs(e.target.value)}
            multiline
            fullWidth
          />
          <Button
            onClick={handleSaveObs}
            variant="contained"
            color="primary"
            style={{ marginTop: 8 }}
          >
            {savingObs ? <CircularProgress /> : "salvar"}
          </Button>
        </Paper>
      </Modal>
      <Box style={{ display: "grid", gridTemplateColumns: "4fr 1fr" }}>
        <Card style={{ overflow: "visible" }}>
          <CardContent>
            <Box>
              {/* Main info (left and main panel) */}
              <Box>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {atividade.candidato_escolhido ? (
                      <>
                        Nome do Colaborador:{" "}
                        {atividade.candidato_escolhido.nome}{" "}
                        {atividade.candidato_escolhido.telefone &&
                          `- ${atividade.candidato_escolhido.telefone}`}
                      </>
                    ) : (
                      "Nome do Colaborador: Não definido"
                    )}
                  </Box>
                  <Box sx={{ textAlign: "left", fontSize: "0.75rem" }}>
                    Código da vaga: {atividade.cod}
                  </Box>
                  <Box sx={{ textAlign: "left", fontSize: "0.75rem" }}>
                    Função: {atividade.atividade.funcao}
                  </Box>
                  <Box sx={{ textAlign: "left", fontSize: "0.75rem" }}>
                    Versão: {atividade.versao}
                  </Box>
                </Typography>
              </Box>
              <Card style={{ overflowX: "auto" }}>
                <CardContent>
                  <Typography component="div" className="info">
                    <Box
                      sx={{
                        fontSize: "0.75rem",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Empreendimento
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Atividade
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Modalidade
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Regime de Contratação
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Solicitação da Vaga
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {/* Data Início do Processo */}
                                  Início do Processo
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Admissão do Candidato
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Responsável Contratação
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Observação
                                </Box>
                              </Box>
                            </th>
                          </tr>
                          <tr>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.atividade.empreendimento}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.atividade.atividade}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {translateTipo[atividade.tipo]}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {
                                  translateRegimeContratacao[
                                    atividade.regime_contratacao
                                  ]
                                }
                              </Box>
                            </td>
                            <td>
                              {format(
                                new Date(atividade.created_at),
                                "dd/MM/yyyy"
                              )}
                            </td>
                            <td>
                              {" "}
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.data_inicio &&
                                  format(
                                    parse(
                                      atividade.data_inicio,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.data_inicio_processo &&
                                  format(
                                    parse(
                                      atividade.data_inicio_processo,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </Box>
                            </td>
                            <td>
                              {atividade.etapa_atual.nome !==
                                "Recrutamento e Seleção" &&
                                atividade.data_admissao &&
                                format(
                                  parse(
                                    atividade.data_admissao,
                                    "yyyy-MM-dd",
                                    new Date(),
                                    {
                                      locale: ptBR,
                                    }
                                  ),
                                  "dd/MM/yyyy"
                                )}
                            </td>
                            <td>
                              <span>{atividade.responsavel_contratacao}</span>
                            </td>
                            <td>
                              <Box
                                sx={{
                                  padding: "8px",
                                  textAlign: "left",
                                  display: "flex",
                                }}
                              >
                                <Tooltip
                                  title={<h3>{atividade.obs}</h3>}
                                  style={{ cursor: "pointer" }}
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="Mais informações"
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                  {/* <span>{atividade.obs.slice(0, 10)}...</span> */}
                                </Tooltip>
                                <Tooltip
                                  title="Editar observação da vaga"
                                  style={{ cursor: "pointer" }}
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="Editar observação da vaga"
                                    onClick={() => {
                                      setObs(atividade.obs);
                                      setObsModalOpen(true);
                                    }}
                                    disabled={atividade.etapa_atual.data_fim}
                                  >
                                    <CreateIcon />
                                  </IconButton>
                                  {/* <span>{atividade.obs.slice(0, 10)}...</span> */}
                                </Tooltip>
                              </Box>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
              <Box
                sx={{
                  marginTop: "8px",
                  marginBottom: "8px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ThemeProvider theme={tableTheme}>
                  <TableContainer
                    component={Paper}
                    style={{ border: "1px solid #3c3f4230" }}
                  >
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}>
                            Nome da Etapa
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            SLA
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            Responsável
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            Data Início
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            Data Fim
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {atividade.etapas.length > 0 &&
                          atividade.etapas.map((row: any) => (
                            <TableRow
                              key={row.nome}
                              hover={true}
                              onClick={() => setCurrentStage({ ...row })}
                              style={{ cursor: "pointer" }}
                              selected={currentStage.nome === row.nome}
                              className={classes.tableRow}
                            >
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                              >
                                <Checkbox
                                  style={{ padding: 0 }}
                                  checked={row.data_fim}
                                  name="checkedF"
                                  disabled
                                  indeterminate={
                                    row.id !== atividade.etapa_atual.id &&
                                    !row.data_fim
                                  }
                                  icon={
                                    <CheckBoxOutlineBlankIcon
                                      fontSize="small"
                                      {...(currentStage.nome === row.nome && {
                                        color: "primary",
                                      })}
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon
                                      fontSize="small"
                                      {...(currentStage.nome === row.nome && {
                                        color: "secondary",
                                      })}
                                    />
                                  }
                                  indeterminateIcon={
                                    <IndeterminateCheckBoxIcon
                                      fontSize="small"
                                      {...(currentStage.nome === row.nome && {
                                        color: "primary",
                                      })}
                                    />
                                  }
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                component="th"
                                scope="row"
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                              >
                                {row.prefixo} - {row.nome}
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                <SlaComponent
                                  data={row}
                                  atividade={atividade}
                                />
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                {row.responsavel}
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                {atividade.etapa_atual.nome ===
                                  "Coleta de Assinaturas e Atualização do Dossiê" &&
                                (row.nome ===
                                  "Coleta de Assinaturas e Atualização do Dossiê" ||
                                  (row.nome === "Realizar Treinamentos" &&
                                    !row.data_fim))
                                  ? format(
                                      parse(
                                        atividade.etapa_atual.data_inicio,
                                        "yyyy-MM-dd",
                                        new Date(),
                                        {
                                          locale: ptBR,
                                        }
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                  : row.data_inicio &&
                                    format(
                                      parse(
                                        row.data_inicio,
                                        "yyyy-MM-dd",
                                        new Date(),
                                        {
                                          locale: ptBR,
                                        }
                                      ),
                                      "dd/MM/yyyy"
                                    )}
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                {row.data_fim &&
                                  format(
                                    parse(
                                      row.data_fim,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ThemeProvider>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <RightPanel
          currentStage={currentStage}
          atividade={atividade}
          setAtividade={setAtividade}
          setCurrentStage={setCurrentStage}
          setPageStates={setPageStates}
          pageStates={pageStates}
          id={params.id}
          email={userStore.email}
        />
      </Box>
    </Box>
  );
}

const translateTipo: any = {
  contratacao: "Contratação",
  transferencia: "Transferência",
  reposicao: "Reposição",
};

const translateRegimeContratacao: any = {
  clt: "CLT",
  estagio: "Estágio",
  aprendiz: "Jovem Aprendiz",
  administrativo: "Administrativo",
  operacional: "Operacional",
};

// const treinamentos: any = [
//   "NR35 - 8 horas",
//   "NR18 - 6 horas",
//   "NR6 - 2 horas",
//   "NR12 (Máquinas e Equipamentos) - 4 horas",
//   "NR12 (Serra Circular de Bancada) - 8 horas",
//   "NR12 (Cremalheira) - 16 horas",
//   "NR12 (Betoneira) - 8 horas",
//   "NR12 (Balancim) - 8 horas",
//   "NR12 (Mini Grua) - 8 horas",
//   "NR12 (Montagem Andaime) - 8 horas",
//   "NR12 (Cadeira) - 8 horas",
//   "NR12 (Martelete) - 8 horas",
//   "NR12 (Pistola finca pino) - 8 horas",
//   "NR10 - 40 horas",
//   "Integração RH Introdução",
//   "Integração Completa com os Departamentos Opus",
// ];
