import * as React from "react";
import { useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CardComponent from "../../../../components/Card/CardComponent";
import SlaComponent from "../../../../components/Sla";
import api from "../../../../api";
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  createMuiTheme,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  createTheme,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  MenuItem,
  ThemeProvider,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import {
  ExpandMore as ExpandMoreIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { differenceInDays, format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import { common } from "@mui/material/colors";
// import { ThemeProvider } from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";

import InfoIcon from "@mui/icons-material/Info";
import CreateIcon from "@mui/icons-material/Create";
import { useStore } from "../../../../stores/rootStore";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const useStyles = makeStyles(() => ({
  tableRow: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#000000",
      color: "#ffffff",
      "& > .MuiTableCell-root": {
        color: "#ffffff",
      },
    },
  },
  tableCell: {
    fontSize: "0.75rem",
  },
  textField: {
    fontSize: "0.75rem",
  },
  checkbox: {
    fontSize: "0.75rem",
  },
  customTextField: {
    fontSize: "0.75rem",
  },
}));

const _fetchData = (
  setAtividade,
  setCurrentStage,
  setPageStates,
  pageStates,
  id
) => {
  (async () => {
    setPageStates({ ...pageStates, loading: true });
    const { data, ok } = await api.get<any>(`/atividades/${id}`);

    if (!ok) {
      setPageStates({ ...pageStates, loading: false, error: true });
      return;
    }

    const {
      data: { Etapas },
    } = await api.get<any>(`/todas-etapas`);

    if (!Etapas) {
      setPageStates({ ...pageStates, loading: false, error: true });
      return;
    }

    // const { data: checklistData } = await api.get<any>("/checklists", {
    //   tipo: data.regime_contratacao,
    // });

    // setChecklist(checklistData);

    let sortedData = {
      ...data,
      etapas: [
        ...Etapas.map((etp) => {
          if (etp.nome === "Início do colaborador na obra") {
            etp.responsavel = data.email_obra;
          }
          return etp;
        }).filter(
          (x) =>
            ![...data.etapas, data.etapa_atual]
              .map((item) => ({
                prefixo: item.prefixo,
                nome: item.nome,
                fase: item.fase,
                sla: item.sla,
                responsavel: item.responsavel,
              }))
              .some((item) => item.nome === x.nome)
        ),
        ...data.etapas.filter((i) => i.prefixo !== data.etapa_atual.prefixo),
        data.etapa_atual,
      ],
    };

    sortedData.etapas = sortedData.etapas.sort((a: any, b: any) =>
      parseInt(a.prefixo?.split("/")[0]) < parseInt(b.prefixo?.split("/")[0])
        ? -1
        : parseInt(b.prefixo?.split("/")[0]) >
          parseInt(a.prefixo?.split("/")[0])
        ? 1
        : 0
    );
    setPageStates({ ...pageStates, loading: false, error: false });
    setAtividade({
      ...sortedData,
      // checklist: checklistData.map((item: any) => ({
      //   nome: item.nome,
      //   ...(sortedData.checklist.find((i: any) => i.nome === item.nome)
      //     ? {
      //         resposta: sortedData.checklist.find((i: any) => i.nome === item.nome)
      //           .resposta,
      //       }
      //     : { resposta: false }),
      // })),
      // treinamentos: treinamentos.map((item: any) => ({
      //   nome: item,
      //   ...(sortedData.treinamentos.find((i: any) => i.nome === item)
      //     ? {
      //         resposta: sortedData.treinamentos.find((i: any) => i.nome === item)
      //           .resposta,
      //       }
      //     : { resposta: false }),
      // })),
    });
    setCurrentStage(sortedData.etapa_atual);
    // setObs(sortedData.etapa_atual.obs);
  })();
};

const getBody = (
  files,
  atividade,
  finalizar,
  colab,
  obs,
  email,
  cancelar,
  motivoInativacao
) => {
  // const obsInput = (
  //   document.getElementById("obs-text-field") as HTMLInputElement
  // ).value;
  // const colabDefinidoInput = (
  //   document.getElementById(
  //     "nome-colab-definido-text-field"
  //   ) as HTMLInputElement
  // )?.value;
  // const filesInput: any = (
  //   document.getElementById("icon-button-file") as HTMLInputElement
  // ).files;
  const filesInput = files;
  const colabDefinidoInput = colab;
  const obsInput = obs;
  const data = {
    atividade: {
      ...atividade,
      ...(colabDefinidoInput &&
        colabDefinidoInput !== "" && {
          candidato_escolhido: {
            nome: colabDefinidoInput.split("(")[0],
            ...(colabDefinidoInput.split("(")[1] && {
              telefone: "(" + colabDefinidoInput.split("(")[1],
            }),
          },
        }),
      email,
    },
    etapa: { obs: obsInput, email, ...(cancelar && { motivoInativacao }) },
  };

  if (filesInput && filesInput.length > 0) {
    const formData: any = new FormData();
    for (let i = 0; i < filesInput.length; ++i) {
      const f = filesInput[i];
      formData.append("files.anexo", f, f.name);
    }
    formData.append("data", JSON.stringify(data));
    return formData;
  }
  return data;
};

const handleApproval = async (
  files,
  atividade,
  setAtividade,
  setCurrentStage,
  setPageStates,
  pageStates,
  id,
  colab,
  obs,
  motivoInativacao,
  email
) => {
  console.log(atividade.responsavel_contratacao);
  if (
    atividade.etapa_atual.nome === "Recrutamento e Seleção" &&
    (!atividade.responsavel_contratacao ||
      atividade.responsavel_contratacao === "")
  ) {
    alert('Favor preencher o campo "Responsável Contratação"');
    return;
  }

  const finalizar = (
    document.getElementById("checkbox-finalizar") as HTMLInputElement
  ).checked;
  const cancelar = (
    document.getElementById("checkbox-cancelar") as HTMLInputElement
  ).checked;

  const body = getBody(
    files,
    atividade,
    finalizar,
    colab,
    obs,
    email,
    cancelar,
    motivoInativacao
  );
  if (finalizar || cancelar) {
    const { data, ok } = await api.post<any>(
      cancelar ? "/atividades/cancelar" : "/atividades/etapa/aprovar",
      body
    );
    if (!ok) {
      alert("Erro");
      console.log(data);
    } else {
      alert("Sucesso");
    }
  } else {
    const { data, ok } = await api.put<any>(
      `/etapas/${atividade.etapa_atual.id}`,
      body
    );
    if (!ok) {
      alert("Erro");
      console.log(data);
    } else {
      alert("Sucesso");
    }
  }
  _fetchData(setAtividade, setCurrentStage, setPageStates, pageStates, id);
  // setShowModal(false);
};

const RightPanel = ({
  currentStage,
  atividade,
  setAtividade,
  setCurrentStage,
  setPageStates,
  pageStates,
  id,
  email,
}) => {
  const [obs, setObs] = React.useState<string>("");
  const [colab, setColab] = React.useState<string>(
    atividade.candidato_escolhido?.nome
  );
  const [dataAdmissao, setDataAdmissao] = React.useState<Date | null>(
    atividade.data_admissao &&
      parse(atividade.data_admissao, "yyyy-MM-dd", new Date(), {
        locale: ptBR,
      })
  );
  const [inativar, setInativar] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<FileList>();
  const classes = useStyles();
  const [motivoInativacao, setMotivoInativacao] = React.useState<string>("");
  const { userStore } = useStore();

  React.useEffect(() => {
    setColab(atividade.candidato_escolhido?.nome);
    setDataAdmissao(
      atividade.data_admissao &&
        parse(atividade.data_admissao, "yyyy-MM-dd", new Date(), {
          locale: ptBR,
        })
    );

  }, [atividade, currentStage]);


  const handleSaveButton = () => {
    // let editedAtividade = { ...atividade };
    // if (atividade.etapa_atual.nome === "Recrutamento e Seleção") {
    const finalizar = (
      document.getElementById("checkbox-finalizar") as HTMLInputElement
    ).checked;

    if (finalizar) {
      if (!dataAdmissao) {
        alert("Favor preencher a data de admissão do colaborador");
        return;
      }

      if (!colab || colab === "") {
        alert("Favor preencher o nome do colaborador");
        return;
      }
    }

    if (inativar && (!motivoInativacao || motivoInativacao === "")) {
      alert("Favor preencher o motivo da inativação");
      return;
    }

    const editedAtividade = { ...atividade, data_admissao: dataAdmissao };
    // }

    handleApproval(
      files,
      editedAtividade,
      setAtividade,
      setCurrentStage,
      setPageStates,
      pageStates,
      id,
      colab,
      obs,
      motivoInativacao,
      email
    );
  };

  const canEdit = () => {
    if (
      currentStage.nome === atividade.etapa_atual.nome &&
      currentStage.data_fim == null
    ) {
      if (userStore.role.name === "Tecnologia da Informação") {
        return true;
      }

      if (!currentStage.responsavel_grupo) {
        alert(
          "Ocorreu um erro.\nFavor entrar em contato com o time de TI para regularizar o cadastro da Etapa."
        );
      }

      if (
        currentStage.responsavel_grupo &&
        currentStage.responsavel_grupo === userStore.role.name
      ) {
        return true;
      }

      if(
        currentStage.responsavel_grupo &&
        currentStage.responsavel_grupo === "Central de Contratações" &&
        userStore.role.name === "Recursos Humanos"
      ) {
        return true;
      }
    }

    return false;
    // return true;
  };

  if (!atividade && !currentStage) {
    return (
      <Box sx={{ marginLeft: "8px" }}>
        <Card>
          <CardContent>
            <Box
              sx={{
                height: "81vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ marginLeft: "8px" }}>
      <Card>
        <CardContent>
          <Box>
            <Typography component="div">
              <Box
                sx={{
                  padding: "0.75rem",
                  textAlign: "left",
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                }}
              >
                {currentStage.nome}
              </Box>
            </Typography>
            <hr style={{ width: "100%" }} />
            <Typography component="div">
              <Box>
                {!canEdit() && currentStage.nome === "Inativa" && (
                  <Box sx={{ m: 1 }}>
                    <Box
                      sx={{
                        padding: "0.75rem",
                        fontSize: "0.75rem",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Motivo da Inativação
                    </Box>
                    <hr style={{ width: "100%" }} />
                    <Box
                      sx={{
                        padding: "0.75rem",
                        fontSize: "0.75rem",
                        textAlign: "left",
                      }}
                    >
                      <span>{currentStage.motivo_inativacao ?? "-"}</span>
                    </Box>
                  </Box>
                )}
                <Box sx={{ m: 1 }}>
                  <Box
                    sx={{
                      padding: "0.75rem",
                      fontSize: "0.75rem",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Observação
                  </Box>
                  <hr style={{ width: "100%" }} />

                  <Box
                    sx={{
                      padding: "0.75rem",
                      fontSize: "0.75rem",
                      textAlign: "left",
                    }}
                  >
                    {canEdit() ? (
                      <TextField
                        InputProps={{
                          classes: {
                            input: classes.textField,
                          },
                        }}
                        label={
                          <Typography style={{ fontSize: "0.75rem" }}>
                            Insira aqui a observação
                          </Typography>
                        }
                        id="obs-text-field"
                        // key="rejection-modal-text-field"
                        name="obs"
                        placeholder="Insira aqui observação"
                        variant="standard"
                        value={obs}
                        onChange={(e) => setObs(e.target.value)}
                        multiline
                        fullWidth
                      />
                    ) : (
                      <>
                        <span>{currentStage.obs ?? "-"}</span>
                      </>
                    )}
                  </Box>
                  {canEdit() && (
                    <>
                      <Box
                        sx={{
                          padding: "0.75rem",
                          fontSize: "0.75rem",
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        Informações da Vaga
                      </Box>
                      <hr style={{ width: "100%" }} />
                      <Box
                        sx={{
                          padding: "0.75rem",
                          fontSize: "0.75rem",
                          textAlign: "left",
                        }}
                      >
                        {currentStage.data_fim == null ? (
                          <>
                            {/* <Box> */}
                            {atividade.colabs_indicados.length > 0 &&
                              atividade.colabs_indicados.filter(
                                (i: any) => i.nome !== ""
                              ).length > 0 && (
                                <Typography component="div">
                                  <Box
                                    sx={{
                                      m: 1,
                                      width: "100%",
                                    }}
                                  >
                                    Colaboradores indicados
                                  </Box>
                                  <Box
                                    sx={{
                                      m: 1,
                                      width: "100%",
                                    }}
                                  >
                                    {atividade.colabs_indicados.map(
                                      (colab: any) => (
                                        <span>
                                          {colab.nome} - {colab.telefone}
                                          <br />
                                        </span>
                                      )
                                    )}
                                  </Box>
                                </Typography>
                              )}

                            <TextField
                              InputProps={{
                                classes: {
                                  input: classes.textField,
                                },
                              }}
                              label={
                                <Typography style={{ fontSize: "0.75rem" }}>
                                  Nome do colaborador
                                </Typography>
                              }
                              id="nome-colab-definido-text-field"
                              name="nome-colab-definido"
                              variant="standard"
                              value={colab}
                              onChange={(e) => setColab(e.target.value)}
                              multiline
                              fullWidth
                            />
                            <Box
                              sx={{
                                marginTop: "0.75rem",
                                width: "100%",
                              }}
                            >
                              <DesktopDatePicker
                                label={
                                  <Typography style={{ fontSize: "0.75rem" }}>
                                    Data de admissão
                                  </Typography>
                                }
                                value={dataAdmissao}
                                onChange={(e) => setDataAdmissao(e)}
                                InputProps={{
                                  style: { fontSize: "0.75rem", width: "100%" },
                                }}
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    {...params}
                                    sx={{ width: "100%" }}
                                  />
                                )}
                              />
                            </Box>
                          </>
                        ) : (
                          <span>
                            {currentStage.obs ? currentStage.obs : "-"}
                          </span>
                        )}
                      </Box>

                    </>
                  )}
                </Box>
                <Box sx={{ m: 1 }}>
                  <Box
                    sx={{
                      padding: "0.75rem",
                      fontSize: "0.75rem",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Anexos
                  </Box>
                  <hr style={{ width: "100%" }} />
                  <Box
                    sx={{
                      padding: "0.75rem",
                      fontSize: "0.75rem",
                      textAlign: "left",
                    }}
                  >
                    {canEdit() ? (
                      <>
                        <input
                          key="rejection-modal-file-input"
                          accept="file/*"
                          id="icon-button-file"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => setFiles(e.target.files)}
                          multiple
                        />
                        <label htmlFor="icon-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<CloudUploadIcon />}
                            component="span"
                            style={{ fontSize: "0.60rem" }}
                          >
                            Escolher arquivo(s)
                          </Button>
                        </label>

                        {files &&
                          files.length > 0 &&
                          Array.from(files).map((file) => (
                            <Typography
                              component="span"
                              style={{ fontSize: "0.60rem" }}
                            >
                              {file.name}
                              <br />
                            </Typography>
                          ))}
                      </>
                    ) : (
                      <>
                        {currentStage.anexo &&
                          currentStage.anexo.length > 0 &&
                          Array.from(currentStage.anexo).map((file: any) => (
                            <Box
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(
                                  `https://app.opus.inc/apis/contratacao${file.url}`,
                                  "_blank"
                                )
                              }
                            >
                              <Typography
                                component="span"
                                style={{
                                  fontSize: "0.75rem",
                                  textDecoration: "underline",
                                  color: "blue",
                                }}
                              >
                                {file.name}
                                <br />
                              </Typography>
                            </Box>
                          ))}
                      </>
                    )}
                  </Box>
                </Box>
                {/* <MuiThemeProvider theme={buttonTheme}> */}
                {canEdit() && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormControlLabel
                      classes={{
                        label: classes.checkbox,
                      }}
                      control={
                        <Checkbox
                          // checked={finalizar}
                          // onChange={() => setFinalizar(!finalizar)}
                          id="checkbox-finalizar"
                          name="checkbox-finalizar"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                        />
                      }
                      label="Finalizar Etapa"
                    />
                    <FormControlLabel
                      classes={{
                        label: classes.checkbox,
                      }}
                      control={
                        <Checkbox
                          checked={inativar}
                          onChange={() => setInativar(!inativar)}
                          id="checkbox-cancelar"
                          name="checkbox-cancelar"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                        />
                      }
                      label="Inativar Atividade"
                    />
                    {inativar && (
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Motivo
                        </InputLabel>
                        <Select
                          value={motivoInativacao}
                          onChange={(e: any) =>
                            setMotivoInativacao(e.target.value)
                          }
                          style={{
                            fontSize: "0.75rem",
                            width: "100%",
                            marginBottom: 8,
                          }}
                          label="Motivo"
                          required
                        >
                          <MenuItem value="Desistência do Candidato">
                            Desistência do Candidato
                          </MenuItem>
                          <MenuItem value="Cancelado pela obra">
                            Cancelado pela obra
                          </MenuItem>
                          <MenuItem value="Outros">Outros</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSaveButton()}
                      style={{ fontSize: "0.70rem" }}
                    >
                      Salvar
                    </Button>
                    <Button
                      variant="outlined"
                      // onClick={() => handleReproval()}
                      style={{ marginTop: "8px", fontSize: "0.70rem" }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                )}
                {/* </ThemeProvider> */}
              </Box>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default function Exibicao() {
  const classes = useStyles();
  const [atividade, setAtividade] = React.useState<any>({
    id: 0,
    tipo: "",
    etapa_atual: {
      id: 0,
      nome: "",
      fase: "",
      sla: "",
      responsavel: "",
      atividade: 0,
      ordem: 0,
      data_inicio: format(new Date(), "yyyy-MM-dd"),
      data_fim: format(new Date(), "yyyy-MM-dd"),
      mao_de_obra: "",
      created_at: "",
      updated_at: "",
      atual: true,
      obs: "",
      anexo: [],
    },
    mao_de_obra: "",
    vaga: "",
    regime_contratacao: "",
    horario_trabalho: "",
    responsavel_entrevista: "",
    obs: "",
    indicacao: false,
    atividade: {
      id: 0,
      empreendimento: "",
      atividade: "",
      funcao: "",
      quantidade: 0,
      produtividade: 0,
      data_inicio_lb: format(new Date(), "yyyy-MM-dd"),
      data_fim_lb: format(new Date(), "yyyy-MM-dd"),
      created_at: new Date(),
      updated_at: new Date(),
    },
    data_inicio: format(new Date(), "yyyy-MM-dd"),
    data_inicio_processo: format(new Date(), "yyyy-MM-dd"),
    previsao_desligamento: format(new Date(), "yyyy-MM-dd"),
    created_at: new Date(),
    updated_at: new Date(),
    colabs_indicados: [],
    candidato_escolhido: "",
    etapas: [
      {
        id: 0,
        nome: "",
        fase: "",
        cod: "",
        sla: "",
        responsavel: "",
        atividade: 0,
        ordem: 0,
        data_inicio: format(new Date(), "yyyy-MM-dd"),
        data_fim: format(new Date(), "yyyy-MM-dd"),
        mao_de_obra: "",
        created_at: new Date(),
        updated_at: new Date(),
        atual: false,
        obs: "",
        anexo: [],
      },
    ],
    checklist: [],
    treinamentos: [],
  });
  const [currentStage, setCurrentStage] = React.useState<any>({
    id: 0,
    nome: "",
    fase: "",
    sla: "",
    responsavel: "",
    atividade: 0,
    ordem: 0,
    data_inicio: format(new Date(), "yyyy-MM-dd"),
    data_fim: format(new Date(), "yyyy-MM-dd"),
    mao_de_obra: "",
    created_at: "",
    updated_at: "",
    atual: true,
    obs: "",
    anexo: [],
  });
  // const [dropdown, setDropdown] = React.useState<boolean>(false);
  // const [finalizar, setFinalizar] = React.useState<boolean>(false);
  const [obs, setObs] = React.useState<string>("");
  const [obsModalOpen, setObsModalOpen] = React.useState<boolean>(false);
  const [savingObs, setSavingObs] = React.useState<boolean>(false);
  // const [files, setFiles] = React.useState<FileList>();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [pageStates, setPageStates] = React.useState({
    success: false,
    error: false,
    loading: true,
  });
  const params = useParams();
  const { userStore } = useStore();

  React.useEffect(() => {
    _fetchData(
      setAtividade,
      setCurrentStage,
      setPageStates,
      pageStates,
      params.id
    );
  }, []);

  if (pageStates.loading) {
    return <CircularProgress size={60} />;
  }

  const buttonTheme = createTheme({
    palette: {
      text: { primary: common.white, secondary: common.white },
      primary: { main: "rgba(255, 0, 0, 0.8)", contrastText: "#ffffff" },
      secondary: { main: "rgba(0, 232, 9, 0.79)", contrastText: "#ffffff" },
    },
  });

  const tableTheme = createTheme({
    palette: {
      secondary: {
        light: "#2c2c2c",
        main: "#3a3a3a",
        dark: "#000000",
      },
      primary: {
        light: "#ffffff",
        main: "#ffffff",
        dark: "#ffffff",
      },
    },
  });

  // const handleReproval = async () => {
  //   const finalizar = (
  //     document.getElementById("checkbox-finalizar") as HTMLInputElement
  //   ).checked;
  //   const body = getBody(finalizar);
  //   const { data, ok } = await api.post<any>(
  //     "/atividades/etapa/reprovar",
  //     body
  //   );
  //   if (!ok) {
  //     alert("Erro");
  //     console.log(data);
  //   } else {
  //     alert("Sucesso");
  //     _fetchData();
  //     setShowModal(false);
  //   }
  // };

  const handleChecklist = (checklist: any, index: any) => {
    const _atividade = { ...atividade };
    _atividade.checklist[index] = {
      ...checklist,
      resposta: !checklist.resposta,
    };
    setAtividade({ ..._atividade });
  };

  const handleTreinamentos = (treinamento: any, index: any) => {
    const _atividade = { ...atividade };
    _atividade.treinamento[index] = {
      ...treinamento,
      resposta: !treinamento.resposta,
    };
    setAtividade({ ..._atividade });
  };

  const handleSaveObs = async () => {
    setSavingObs(true);

    const { ok } = await api.put<any>(`/atividades/${atividade.id}`, {
      obs,
      email: userStore.email,
      indicacao: atividade.indicacao,
    });

    setSavingObs(false);

    if (!ok) {
      alert("Ocorreu um erro ao tentar salvar a observação.");
    } else {
      alert("Observação salva com sucesso.");
      setObsModalOpen(false);
      _fetchData(
        setAtividade,
        setCurrentStage,
        setPageStates,
        pageStates,
        atividade.id
      );
    }
  };

  const handleIndicacaoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIndicacao = event.target.checked;
    setAtividade({ ...atividade, indicacao: newIndicacao });

    try {
      const { ok } = await api.put<any>(`/atividades/${atividade.id}`, {
        indicacao: newIndicacao,
        email: userStore.email,
      });

      if (!ok) {
        alert("Ocorreu um erro ao tentar salvar a indicação.");
      }
    } catch (error) {
      console.error("Erro ao tentar salvar a indicação:", error);
      alert("Ocorreu um erro ao tentar salvar a indicação.");
    }
  };

  const allowedEmails = ["juliana.oliveira@opus.inc", "jenaina.dacheri@opus.inc"];

const confirmStepBack = () => window.confirm("Tem certeza que deseja voltar etapa?");

const handleBackStep = async () => {
  if (!confirmStepBack()) {
    return;
  }

  const etapasParaReverter = [
    "3/7",
    "4/7",
    "5/7",
    "6/7",
    "7/7"
  ];

  console.log(atividade.etapas);

  const etapas = atividade.etapas
    .filter(etapa => etapasParaReverter.includes(etapa.prefixo) && etapa.id)
    .sort((a, b) => Number(b.prefixo.split("/")[0]) - Number(a.prefixo.split("/")[0]));

  for (const etapa of etapas) {
    try {
      const response = await api.put(`/etapas/${etapa.id}`, { data_fim: null, data_inicio: null});
      if (!response.ok) {
        alert(`Erro ao tentar reverter a etapa '${etapa.nome}'.`);
        return;
      }
    } catch (error) {
      console.error(`Erro ao reverter a etapa '${etapa.nome}':`, error);
      alert(`Ocorreu um erro ao tentar reverter a etapa '${etapa.nome}'.`);
      return;
    }
  }

  const etapaRecrutamento = atividade.etapas.find(etapa => etapa.prefixo === "2/7");
  if (etapaRecrutamento) {
    try {
      const responseEtapaRecrutamento = await api.put(`/etapas/${etapaRecrutamento.id}`, { data_fim: null});
      if (!responseEtapaRecrutamento.ok) {
        console.error("Erro ao limpar a data_fim da etapa 'Recrutamento e Seleção'.");
        alert("Ocorreu um erro ao tentar limpar a etapa 'Recrutamento e Seleção'.");
        return;
      }
      let novaVersao = atividade.versao;
      if (!atividade.versao) {
        novaVersao = 2;
      }
      else {
        novaVersao += 1;
      }
      const responseAtividade = await api.put(`/atividades/${atividade.id}`, {
        etapa_atual: etapaRecrutamento.id,
        email: userStore.email,
        versao: novaVersao,
        candidato_escolhido: { nome: "Não definido" },
        data_admissao: null
      });


      if (!responseAtividade.ok) {
        console.error("Erro ao atualizar a atividade para 'Recrutamento e Seleção'.");
        alert("Ocorreu um erro ao atualizar a atividade.");
        return;
      }
      alert("Atividade atualizada para a etapa 'Recrutamento e Seleção' com sucesso.");
    } catch (error) {
      console.error("Erro ao tentar limpar/atualizar a etapa 'Recrutamento e Seleção':", error);
      alert("Ocorreu um erro ao tentar atualizar a atividade para 'Recrutamento e Seleção'.");
      return;
    }
  } else {
    alert("Etapa 'Recrutamento e Seleção' não encontrada.");
  }

  _fetchData(setAtividade, setCurrentStage, setPageStates, pageStates, atividade.id);
};

  return (
    <div
      // elevation={3}
      // width="100%"
      // height="100%"
      style={{
        margin: 8,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="editar-etapa-atual"
        aria-describedby="modal-para-editar-a-etapa-atual"
        key="rejection-modal-component"
        disableEnforceFocus
      >
        <CardComponent
          width="100%"
          sx={{
            width: "70%",
            position: "absolute",
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
        >
          <Typography component="div">
            <Box
              sx={{
                m: 1,
                width: "100%",
                fontSize: "1.3rem",
                fontWeight: "bold",
              }}
            >
              Editar Etapa
            </Box>
          </Typography>
          <Box
            sx={{
              m: 1 /*, display: "grid", gridTemplateColumns: "2fr 1fr 1fr"*/,
            }}
          >
            <Box>
              {atividade.etapa_atual.nome === "Recrutamento e Seleção" &&
                atividade.colabs_indicados.length > 0 &&
                atividade.colabs_indicados.filter((i: any) => i.nome !== "")
                  .length > 0 && (
                  <Typography component="div">
                    <Box
                      sx={{
                        m: 1,
                        width: "100%",
                      }}
                    >
                      Colaboradores indicados
                    </Box>
                    <Box
                      sx={{
                        m: 1,
                        width: "100%",
                      }}
                    >
                      {atividade.colabs_indicados.map((colab: any) => (
                        <span>
                          {colab.nome} {colab.telefone && `-${colab.telefone}`}
                          <br />
                        </span>
                      ))}
                    </Box>
                  </Typography>
                )}
              <Box
                sx={{ m: 1, display: "grid", gridTemplateColumns: "1fr 1fr" }}
              >
                <Box sx={{ p: 1 }}>
                  <Typography component="div">
                    <Box sx={{ m: 1, textAlign: "left", fontWeight: "bold" }}>
                      Observação
                    </Box>
                  </Typography>
                  <hr style={{ width: "100%" }} />
                  <TextField
                    id="obs-text-field"
                    key="rejection-modal-text-field"
                    name="obs"
                    label="Insira aqui a observação"
                    placeholder="Insira aqui observação"
                    variant="standard"
                    multiline
                    fullWidth
                  />
                  {atividade.etapa_atual.nome === "Recrutamento e Seleção" && (
                    <TextField
                      id="nome-colab-definido-text-field"
                      key="rejection-modal-text-field"
                      name="nome-colab-definido"
                      label="Insira aqui o nome do colaborador definido"
                      placeholder="Insira aqui o nome do colaborador definido"
                      variant="standard"
                      multiline
                      fullWidth
                    />
                  )}
                </Box>
                <Box sx={{ p: 1 }}>
                  <Typography component="div">
                    <Box sx={{ m: 1, textAlign: "left", fontWeight: "bold" }}>
                      Anexo
                    </Box>
                  </Typography>
                  <hr style={{ width: "100%" }} />
                  <input
                    key="rejection-modal-file-input"
                    accept="file/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: "none" }}
                    multiple
                  />
                  <label htmlFor="icon-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      component="span"
                    >
                      Escolher arquivo(s)
                    </Button>
                  </label>
                  {(
                    document.getElementById(
                      "icon-button-file"
                    ) as HTMLInputElement
                  )?.files?.length > 0 &&
                    Array.from(
                      (
                        document.getElementById(
                          "icon-button-file"
                        ) as HTMLInputElement
                      ).files
                    ).forEach((file) => <span>{file.name}</span>)}
                </Box>
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "block",
                overflow: "auto",
                textAlign: "justify",
                height: "350px",
                width: "100%",
              }}
            >
              <Typography component="div">
                <Box
                  sx={{
                    m: 1,
                    width: "100%",
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                  }}
                >
                  Checklist
                </Box>
              </Typography>
              {atividade.checklist.length > 0 &&
                atividade.checklist.map((checklist, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checklist.resposta}
                        onChange={() => handleChecklist(checklist, index)}
                        name={checklist.nome}
                      />
                    }
                    label={checklist.nome}
                  />
                ))}
            </Box>
            <Box
              sx={{
                display: "block",
                overflow: "auto",
                textAlign: "justify",
                height: "350px",
                width: "100%",
              }}
            >
              <Typography component="div">
                <Box
                  sx={{
                    m: 1,
                    width: "100%",
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                  }}
                >
                  Treinamentos
                </Box>
              </Typography>
              {atividade.treinamentos.length > 0 &&
                atividade.treinamentos.map((treinamento, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={treinamento.resposta}
                        onChange={() => handleTreinamentos(treinamento, index)}
                        name={treinamento.nome}
                      />
                    }
                    label={treinamento.nome}
                  />
                ))}
            </Box> */}
          </Box>
          <ThemeProvider theme={buttonTheme}>
            <Box
              sx={{
                m: 1,
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 0.5fr 1fr 1fr",
              }}
            >
              <div />
              <Button
                variant="contained"
                color="secondary"
                // onClick={() => handleApproval()}
              >
                Aprovar
              </Button>
              <div />
              <Button
                variant="contained"
                color="primary"
                // onClick={() => handleReproval()}
              >
                Reprovar
              </Button>
              <div />
            </Box>
          </ThemeProvider>
        </CardComponent>
      </Modal>
      <Modal
        open={obsModalOpen}
        onClose={() => setObsModalOpen(false)}
        aria-labelledby="editar-observacao"
        aria-describedby="modal-para-editar-a-observacao"
        key="rejection-modal-component"
        disableEnforceFocus
      >
        <Paper
          elevation={5}
          style={{
            width: "70%",
            position: "absolute",
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            padding: "2rem",
            display: "grid",
            placeItems: "center",
          }}
        >
          <TextField
            label="Insira aqui a observação"
            id="obs-text-field"
            // key="rejection-modal-text-field"
            name="obs"
            placeholder="Insira aqui observação"
            variant="standard"
            value={obs}
            onChange={(e) => setObs(e.target.value)}
            multiline
            fullWidth
          />
          <Button
            onClick={handleSaveObs}
            variant="contained"
            color="primary"
            style={{ marginTop: 8 }}
          >
            {savingObs ? <CircularProgress /> : "salvar"}
          </Button>
        </Paper>
      </Modal>
      <Box style={{ display: "grid", gridTemplateColumns: "4fr 1fr" }}>
        <Card>
          <CardContent>
            <Box>
              {/* Main info (left and main panel) */}
              <Box>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {atividade.candidato_escolhido ? (
                      <>
                        Nome do Colaborador:{" "}
                        {atividade.candidato_escolhido.nome}{" "}
                        {atividade.candidato_escolhido.telefone &&
                          `- ${atividade.candidato_escolhido.telefone}`}
                      </>
                    ) : (
                      "Nome do Colaborador: Não definido"
                    )}
                  </Box>
                  <Box sx={{ textAlign: "left", fontSize: "0.75rem" }}>
                    Código da vaga: {atividade.cod}
                  </Box>
                  <Box sx={{ textAlign: "left", fontSize: "0.75rem" }}>
                    Função: {atividade.atividade.funcao}
                  </Box>
                  <Box sx={{ textAlign: "left", fontSize: "0.75rem" }}>
                    Versão: {atividade.versao ?? 1}
                  </Box>
                </Typography>
              </Box>
              <Card style={{ overflowX: "auto" }}>
                <CardContent>
                  <Typography component="div" className="info">
                    <Box
                      sx={{
                        fontSize: "0.75rem",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Empreendimento
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Atividade
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Modalidade
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Regime de Contratação
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Solicitação da Vaga
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {/* Data Início Prevista */}
                                  Data da última modificação
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {/* Data Início do Processo */}
                                  Início do Processo
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Admissão Candidato
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Responsável Contratação
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Observação
                                </Box>
                              </Box>
                            </th>
                            <th>
                              <Box>
                                <Box
                                  sx={{
                                    padding: "8px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Indicação
                                </Box>
                              </Box>
                            </th>
                          </tr>
                          <tr>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                            <th>
                              <hr style={{ width: "100%" }} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>

                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.atividade.empreendimento}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.atividade.atividade}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {translateTipo[atividade.tipo]}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {
                                  translateRegimeContratacao[
                                    atividade.regime_contratacao
                                  ]
                                }
                              </Box>
                            </td>
                           <td>
                              {format(
                                new Date(atividade.created_at),
                                "dd/MM/yyyy"
                              )}
                            </td>
                            <td>
                              {" "}
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.updated_at &&
                                  format(
                                      new Date(atividade.updated_at),
                                      "dd/MM/yyyy",
                                      {
                                        locale: ptBR,
                                      }
                                  )}
                              </Box>
                            </td>
                            <td>
                              <Box sx={{ padding: "8px", textAlign: "left" }}>
                                {atividade.data_inicio_processo &&
                                  format(
                                    parse(
                                      atividade.data_inicio_processo,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </Box>
                            </td>
                            <td>
                              {atividade.etapa_atual.nome !==
                                "Recrutamento e Seleção" &&
                                atividade.data_admissao &&
                                format(
                                  parse(
                                    atividade.data_admissao,
                                    "yyyy-MM-dd",
                                    new Date(),
                                    {
                                      locale: ptBR,
                                    }
                                  ),
                                  "dd/MM/yyyy"
                                )}
                            </td>
                            <td>
                              {!atividade.etapa_atual.data_fim ? (
                                <FormControl>
                                  <InputLabel id="select-responsible">
                                    {/* Responsável */}
                                  </InputLabel>
                                  <Select
                                    value={
                                      atividade.responsavel_contratacao
                                        ? userStore.rhPeople.find(
                                            (i) =>
                                              i.split(" ")[0] ===
                                              atividade.responsavel_contratacao.split(
                                                " "
                                              )[0]
                                          ) ?? atividade.responsavel_contratacao
                                        : ""
                                    }
                                    onChange={(e) =>
                                      setAtividade({
                                        ...atividade,
                                        responsavel_contratacao: e.target.value,
                                      })
                                    }
                                    style={{
                                      fontSize: "0.75rem",
                                      width: "100%",
                                      marginBottom: 8,
                                    }}
                                    label="Responsável"
                                    required
                                  >
                                    {userStore.rhPeople.map(
                                      (rhPerson: string, index: number) => (
                                        <MenuItem
                                          key={rhPerson + "-" + index}
                                          value={rhPerson}
                                        >
                                          {rhPerson}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              ) : (
                                <span>{atividade.responsavel_contratacao}</span>
                              )}
                            </td>
                            <td>
                              <Box
                                sx={{
                                  padding: "8px",
                                  textAlign: "left",
                                  display: "flex",
                                }}
                              >
                                <Tooltip
                                  title={<h3>{atividade.obs}</h3>}
                                  style={{ cursor: "pointer" }}
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="Mais informações"
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                  {/* <span>{atividade.obs.slice(0, 10)}...</span> */}
                                </Tooltip>
                                <Tooltip
                                  title="Editar observação da vaga"
                                  style={{ cursor: "pointer" }}
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="Editar observação da vaga"
                                    onClick={() => {
                                      setObs(atividade.obs);
                                      setObsModalOpen(true);
                                    }}
                                    disabled={atividade.etapa_atual.data_fim}
                                  >
                                    <CreateIcon />
                                  </IconButton>
                                  {/* <span>{atividade.obs.slice(0, 10)}...</span> */}
                                </Tooltip>
                              </Box>
                            </td>
                             {/* Add checkbox for "Indicação" */}
                          <td>
                            <Box sx={{ padding: "8px", textAlign: "left" }}>
                              <Checkbox
                                checked={atividade.indicacao}
                                onChange={handleIndicacaoChange}
                                name="checkbox-indicacao"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                              />
                            </Box>
                          </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
              <Box
                sx={{
                  marginTop: "8px",
                  marginBottom: "8px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ThemeProvider theme={tableTheme}>
                  <TableContainer
                    component={Paper}
                    style={{ border: "1px solid #3c3f4230" }}
                  >
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}>
                            Nome da Etapa
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            SLA
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            Responsável
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            Data Início
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            Data Fim
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {atividade.etapas.length > 0 &&
                          atividade.etapas.map((row: any) => (
                            <TableRow
                              key={row.nome}
                              hover={true}
                              onClick={() => setCurrentStage({ ...row })}
                              style={{ cursor: "pointer" }}
                              selected={currentStage.nome === row.nome}
                              className={classes.tableRow}
                            >
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                              >
                                <Checkbox
                                  style={{ padding: 0 }}
                                  checked={row.data_fim}
                                  name="checkedF"
                                  disabled
                                  indeterminate={
                                    row.id !== atividade.etapa_atual.id &&
                                    !row.data_fim
                                  }
                                  icon={
                                    <CheckBoxOutlineBlankIcon
                                      fontSize="small"
                                      {...(currentStage.nome === row.nome && {
                                        color: "primary",
                                      })}
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon
                                      fontSize="small"
                                      {...(currentStage.nome === row.nome && {
                                        color: "primary",
                                      })}
                                    />
                                  }
                                  indeterminateIcon={
                                    <IndeterminateCheckBoxIcon
                                      fontSize="small"
                                      {...(currentStage.nome === row.nome && {
                                        color: "primary",
                                      })}
                                    />
                                  }
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                component="th"
                                scope="row"
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                              >
                                {row.prefixo} - {row.nome}
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                <SlaComponent
                                  data={row}
                                  atividade={atividade}
                                />
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                {row.responsavel}
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                {row.data_inicio &&
                                  format(
                                    parse(
                                      row.data_inicio,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </TableCell>
                              <TableCell
                                style={{
                                  ...(currentStage.nome === row.nome && {
                                    color: "white",
                                  }),
                                }}
                                className={classes.tableCell}
                                align="left"
                              >
                                {row.data_fim &&
                                  format(
                                    parse(
                                      row.data_fim,
                                      "yyyy-MM-dd",
                                      new Date(),
                                      {
                                        locale: ptBR,
                                      }
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ThemeProvider>
              </Box>
            </Box>
            <Box
              sx={{
                width: "15%",
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
              }}
            >
             {/**/}
             {
               ["3/7", "4/7", "5/7", "6/7", "7/7"].includes(atividade.etapa_atual.prefixo) &&
               allowedEmails.includes(userStore.email) &&
               (
               <Button
                variant="contained"
                color="primary"
                onClick={handleBackStep}
                style={{ fontSize: "0.70rem" }}
              >
                Voltar Etapa
              </Button>
            )}
       </Box>
          </CardContent>
        </Card>
        <RightPanel
          currentStage={currentStage}
          atividade={atividade}
          setAtividade={setAtividade}
          setCurrentStage={setCurrentStage}
          setPageStates={setPageStates}
          pageStates={pageStates}
          id={params.id}
          email={userStore.email}
        />
      </Box>
    </div>
  );
}

const translateTipo: any = {
  contratacao: "Contratação",
  transferencia: "Transferência",
  reposicao: "Reposição",
};

const translateRegimeContratacao: any = {
  clt: "CLT",
  estagio: "Estágio",
  aprendiz: "Jovem Aprendiz",
  administrativo: "Administrativo",
  operacional: "Operacional",
};

// const treinamentos: any = [
//   "NR35 - 8 horas",
//   "NR18 - 6 horas",
//   "NR6 - 2 horas",
//   "NR12 (Máquinas e Equipamentos) - 4 horas",
//   "NR12 (Serra Circular de Bancada) - 8 horas",
//   "NR12 (Cremalheira) - 16 horas",
//   "NR12 (Betoneira) - 8 horas",
//   "NR12 (Balancim) - 8 horas",
//   "NR12 (Mini Grua) - 8 horas",
//   "NR12 (Montagem Andaime) - 8 horas",
//   "NR12 (Cadeira) - 8 horas",
//   "NR12 (Martelete) - 8 horas",
//   "NR12 (Pistola finca pino) - 8 horas",
//   "NR10 - 40 horas",
//   "Integração RH Introdução",
//   "Integração Completa com os Departamentos Opus",
// ];
