import { useEffect, useState } from "react";
import { differenceInDays, format, parse, parseISO } from "date-fns";
import { formatMoney } from "../../../utils/Format";
import { Box, Divider, Typography } from "@mui/material";

import { notasApi } from "../../../api";
import { HttpStatusEnum } from "../../../helpers";
import { useStore } from "../../../stores/rootStore";
import { CnpjMask } from "../../../utils/Mask";
import NotaSLA from "./components/NotaSLA";
import { ConvertInitialToUpperCase } from "../../../utils/ConvertInitialToUpperCase";
import DataTable from "../../../components/dataTable";
import { Loading } from "../../../components/loading";
import Danfe from "./components/Danfe";
import Observacao from "./components/Observacao";
import NumeroPedido from "./components/NumeroPedido";
import Responsavel from "./components/Responsavel";
import { useLocation } from "react-router-dom";
import NotaOrigem from "./components/NotaOrigem";
import GerarFinanceiro from "./components/GerarFinanceiro";
import PrevisaoChegada from "./components/PrevisaoChegada";
import { utcToZonedTime } from "date-fns-tz";
import Pendencia from "./components/Pendencia";
import NotaCancelada from "./components/NotaCancelada";

const locais = {
  lancamento: "Lançamento",
  classificacao: "Classificação",
  consulta: "Consulta",
};

export default function Listagem() {
  const location = useLocation();
  const local = location.pathname.split("/").at(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [notas, setNotas] = useState<any[]>([]);
  const [selectedNotas, setSelectedNotas] = useState<number[]>([]);
  const { newAlert, userStore } = useStore();
  const [defaultFilters, setDefaultFilters] = useState<any>();

  const columns = [
    ...(local === "classificacao"
      ? [
          {
            field: "sla",
            headerName: "SLA",
            align: "left",
            disablePadding: true,
            showOnFilter: false,
            renderCell: (params: any) => <NotaSLA data={params} />,
          },
          {
            field: "status_cancelada",
            headerName: "Status Cancelada",
            align: "center",
            disablePadding: true,
            showOnFilter: true,
          },
        ]
      : []),
    {
      field: "spe",
      headerName: "SPE",
      align: "left",
      disablePadding: true,
    },
    {
      field: "xml_",
      headerName: "Danfe",
      align: "center",
      disablePadding: true,
      showOnFilter: false,
      renderCell: (params: any) => <Danfe data={params} />,
    },
    {
      field: "dataEmissao",
      headerName: "Emissão",
      align: "center",
      disablePadding: true,
      ...(local === "lancamento" && { showOnFilter: false }),
    },
    {
      field: "numero",
      headerName: "Número",
      align: "left",
      disablePadding: true,
    },
    {
      field: "valor",
      headerName: "Valor",
      align: "left",
      disablePadding: true,
      showOnFilter: false,
    },
    {
      field: "fornecedor",
      headerName: "Fornecedor",
      align: "left",
      disablePadding: true,
    },
    {
      field: "natureza",
      headerName: "Natureza",
      align: "left",
      disablePadding: true,
      showOnFilter: true,
    },
    {
      field: "qtd_medicoes_aprovadas",
      headerName: "Medições Aprovadas",
      align: "left",
      disablePadding: true,
      showOnFilter: false,
    },

    ...(local === "classificacao"
      ? [
          {
            field: "adiantamento_pendente",
            headerName: "Adiant. Pendente?",
            align: "left",
            disablePadding: true,
            showOnFilter: false,
          },
        ]
      : []),
    {
      field: "gerar_financeiro",
      headerName: "Gerar Financeiro?",
      align: "left",
      showOnFilter: false,
      disablePadding: true,
      ...(local === "classificacao" && {
        renderCell: (params: any) => <GerarFinanceiro data={params} />,
      }),
    },
    ...(local === "classificacao"
      ? [
          {
            field: "nota_cancelada",
            headerName: "Nota Cancelada?",
            align: "left",
            disablePadding: true,
            showOnFilter: false,
            renderCell: (params: any) => <NotaCancelada data={params} />,
          },
        ]
      : []),
    ...(local !== "lancamento"
      ? [
          {
            field: "origem",
            headerName: "Origem",
            align: "left",
            disablePadding: true,
            ...(local === "classificacao" && {
              renderCell: (params: any) => <NotaOrigem data={params} />,
            }),
            showOnFilter: false,
          },
        ]
      : []),
    ...(local !== "classificacao"
      ? [
          {
            field: "pendencia",
            headerName: "Pendência",
            align: "left",
            disablePadding: true,
            ...(local === "lancamento" && { showOnFilter: true }),
            ...(local === "consulta" && { showOnFilter: true }),
            ...(local !== "consulta" && {
              renderCell: (params: any) => <Pendencia data={params} />,
            }),
            showOnFilter: true,
          },
        ]
      : []),

    {
      field: "responsavel",
      headerName: "Responsável",
      align: "left",
      disablePadding: true,
      ...(local === "classificacao" && { showOnFilter: false }),
      ...(local === "lancamento" && { showOnFilter: true }),
      ...(local === "consulta" && { showOnFilter: true }),
      ...(local !== "consulta" && {
        renderCell: (params: any) => <Responsavel data={params} />,
      }),
      showOnFilter: true,
    },
    {
      field: "ultima_alteracao_responsavel",
      headerName: "Última alteração responsável",
      align: "left",
      showOnFilter: false,
    },
    ...(local !== "classificacao"
      ? [
          {
            field: "numero_pedido",
            headerName: "Nº Pedido",
            align: "left",
            disablePadding: true,
            ...(local === "lancamento" && { showOnFilter: true }),
            ...(local === "consulta" && { showOnFilter: false }),
            ...(local === "lancamento" && {
              renderCell: (params: any) => <NumeroPedido data={params} />,
            }),
          },
        ]
      : []),
    ...(local !== "classificacao"
      ? [
          {
            field: "previsao_chegada",
            headerName: "Prev. Chegada",
            align: "left",
            disablePadding: true,
            ...(local === "lancamento" && { showOnFilter: true }),
            ...(local === "consulta" && { showOnFilter: false }),
            ...(local === "lancamento" && {
              renderCell: (params: any) => <PrevisaoChegada data={params} />,
            }),
          },
        ]
      : []),
    ...(local !== "classificacao"
      ? [
          {
            ...(local === "lancamento" && { field: "observacao_" }),
            ...(local !== "lancamento" && { field: "observacao" }),
            headerName: "Observação",
            align: "center",
            disablePadding: true,
            ...(local === "lancamento" && {
              renderCell: (params: any) => <Observacao data={params} />,
            }),
            showOnFilter: false,
          },
        ]
      : []),
    {
      field: "status_cancelada",
      headerName: "Status Cancelada",
      align: "center",
      disablePadding: true,
      ...(local === "classificacao" && { showOnFilter: true }),
    },
  ];


  const rowsTable = notas.map((nota) => {
    return {
      ...nota,
      styles: {
        spe: { textAlign: "left", fontSize: "0.75rem" },
        status: { textAlign: "left", fontSize: "0.75rem" },
        fornecedor: { textAlign: "left", fontSize: "0.75rem" },
        valor: { textAlign: "left", fontSize: "0.75rem" },
        numero: { textAlign: "left", fontSize: "0.75rem" },
        dataEmissao: { textAlign: "center", fontSize: "0.75rem" },
        ultima_alteracao_responsavel: {
          textAlign: "left",
          fontSize: "0.75rem",
        },
        uf: { textAlign: "center", fontSize: "0.75rem" },
        cfop: { textAlign: "center", fontSize: "0.75rem" },
        nameProjeto: { textAlign: "left", fontSize: "0.75rem" },
        centroCusto: { textAlign: "center", fontSize: "0.75rem" },
        observacao_: { textAlign: "center", fontSize: "0.75rem" },
        xml_: { textAlign: "center", fontSize: "0.75rem" },
        status_cancelada: { textAlign: "center", fontSize: "0.75rem" },
      },
      triggerReload: (row: any) => {
        const idx = rowsTable.findIndex((el) => el.id === row.id);
        rowsTable[idx] = { ...row };
      },
    };
  });

  function calculateSla(data: Date) {
    if (data.addDays(1) <= new Date()) {
      return "Dentro";
    } else {
      return "Fora";
    }
  }

  const fornecedoresExcluidos = [
    "LAURA F.DE S.MECCHI",
  ];
  function formatNota(data: any[]) {
    return data.filter(Boolean)
    .filter(({ attributes: nota }) => !fornecedoresExcluidos.includes(nota.fornecedor))
    .map(({ attributes: nota, id }) => ({
      id: id,
      spe: nota.filial,
      sla: calculateSla(
        parse(
          nota.ultima_alteracao_responsavel ?? nota.emissao,
          "yyyy-MM-dd",
          new Date()
        )
      ),
      fornecedor: nota.fornecedor,
      dataEmissao: nota.emissao
        ? format(parse(nota.emissao, "yyyy-MM-dd", new Date()), "dd/MM/yyyy")
        : "",
      observacao: nota.observacao,
      chave: nota.chave,
      cnpj: nota.rcnf_st_cnpjemitres ? CnpjMask(nota.rcnf_st_cnpjemitres) : "",
      numero: nota.nf,
      valor: formatMoney(nota.valor),
      status_importacao:
        nota.status_importacao === "Aberto/Pendente"
          ? "Pendente"
          : nota.status_importacao,
      responsavel: nota.responsavel || "Não Informado",
      ultima_alteracao_responsavel: nota.ultima_alteracao_responsavel
        ? format(
            utcToZonedTime(
              parseISO(nota.ultima_alteracao_responsavel),
              "America/Sao_Paulo"
            ),
            "dd/MM/yyyy HH:mm"
          )
        : "",
      numero_pedido: nota.numero_pedido,
      tipo_nf: nota.tipo_nf,
      origem: nota.nota_origem,
      natureza: ConvertInitialToUpperCase(nota.natureza),
      gerar_financeiro: nota.gerar_financeiro,
      adiantamento_pendente: nota.adiantamento_pendente,
      qtd_medicoes_aprovadas: nota.qtd_medicoes_aprovadas,
      pendencia: nota.pendencia,
      inscricao_cadastral: nota.inscricao_cadastral,
      codigo_verificacao: nota.codigo_verificacao,
      status_cancelada: nota.status_cancelada,
      previsao_chegada: nota.previsao_chegada
        ? parse(nota.previsao_chegada, "yyyy-MM-dd", new Date())
        : null,
    }));
  }

  async function fetchData() {
    setIsLoading(true);

    try {
        const response = await notasApi.get<any>("/nota-fiscals", {
            _limit: 10000,
            "filters[status][$eq]": "importada",
            username:
                process.env.NODE_ENV === "development"
                    ? "joao.santos"
                    : userStore.username,
            ...(local === "classificacao" && {
                "filters[responsavel][$in][0]": "Contabilidade",
                "filters[responsavel][$in][1]": "Não Informado",
            }),
            ...(local === "lancamento" && {
                "filters[responsavel][$notIn][0]": "Contabilidade",
                "filters[responsavel][$notIn][1]": "Não Informado",
            }),
        });

        if (response.ok && response.data) {
            const formattedNotas = formatNota(response.data.data || []);
            setNotas(formattedNotas);
        } else {
            throw new Error('Erro ao carregar os dados.');
        }
    } catch (e) {
        console.error(e);
        newAlert("error", e.message || "Erro ao carregar os dados.");
    } finally {
        setIsLoading(false);
    }

  }

  useEffect(() => {
    try {
      setDefaultFilters({});
      fetchData();
    } catch (e) {
      console.log(e);
    }
  }, [local]);

  return (
    <Box>
      <Box component="header" mb={local === "classificacao" && 5}>
        <Typography variant="h5" mb={2}>
          Notas Fiscais - {locais[local]}
        </Typography>
        {local === "lancamento" && (
          <Typography variant="body1" mb={5}>
            Notas classificadas pela CTB
          </Typography>
        )}
        <Divider />
      </Box>

      {!isLoading ? (
        <>
          <DataTable
            columns={columns}
            rows={rowsTable}
            initialPageSize={100}
            dense
            clearSelecteds
            selectedParent={selectedNotas}
            onRowClick={() => {}}
            onSelectionModelChange={setSelectedNotas}
            setRows={setNotas}
            defaultFilters={defaultFilters}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
}
